import {get, post} from "./index.js";

/**
 * 获取岗位功能范围
 * @param data
 * @returns {Promise<unknown>}
 */
export const getFuncScope = param => {
    return get("/apis/role-func-scope/getFuncScope", param).then(res => res);
};

/**
 * 保存岗位功能授权范围
 * @param data
 * @returns {Promise<unknown>}
 */
export const saveFuncScope = data => {
    return post("/apis/role-func-scope/saveFuncScope", data).then(res => res);
};

/**
 * 分页获取岗位列表
 * @param data
 * @returns {Promise<List<RoleVo>>}
 */
export const getListByPage = data => {
    return post("apis/role/getListByPage", data).then(res => res);
};

/**
 * 保存岗位信息
 * @param data
 * @returns {Promise<Long id>}
 */
export const saveRole = data => {
    return post("apis/role/saveRole", data).then(res => res);
};

/**
 * 获取岗位信息
 * @param param
 * @returns {Promise<RoleVo>}
 */
export const getRole = param => {
    return get("apis/role/getRole", param).then(res => res);
};

/**
 * 删除当前选中岗位信息
 * @param param
 * @returns {Promise<unknown>}
 */
export const deleteRole = param => {
    return get("apis/role/delRole", param).then(res => res);
};

/**
 * 获取全部岗位信息列表
 * @returns {Promise<unknown>}
 */
export const ReqRoleGetRoles = param => {
    return get("apis/role/getRoles", param).then(res => res);
};

export const updateRoles = data => {
    return post("apis/role-func-scope/updateFuncScope",data).then(res => res);
}
